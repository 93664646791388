<template>
    <div class="mb-4" v-if="isVisible">
        <b-list-group class="shadow">
            <b-list-group-item v-if="shop.payment_provider_status == 0" to="/manage/organisation/setup/payment">
                <div class="float-left">
                    <font-awesome-icon class="intro-icon mt-1" icon="fa-solid fa-euro" size="2x" />
                </div>
                <div class="icon-text">
                    <font-awesome-icon class="arrow-icon float-right" icon="fa-solid fa-chevron-right" />
                    <div>Betalingskoppeling toevoegen</div>
                    <p class="text-muted mb-0">Voeg een betalingspartner toe om betalingen te ontvangen.</p>
                </div>
            </b-list-group-item>
            <!--
            <b-list-group-item to="/manage/organisation/setup/details">
                <div class="float-left">
                    <font-awesome-icon class="intro-icon mt-1" icon="fa-solid fa-building" size="2x" />
                </div>
                <div class="icon-text">
                    <font-awesome-icon class="arrow-icon float-right" icon="fa-solid fa-chevron-right" />
                    <div>Extra informatie aanleveren</div>
                    <p class="text-muted mb-0">Om te kunnen starten met de verkoop hebben we wat informatie van je bedrijf nodig.</p>
                </div>
            </b-list-group-item>
            <b-list-group-item v-b-modal.bankaccount>
                <div class="float-left">
                    <font-awesome-icon class="intro-icon mt-1" icon="fa-solid fa-euro" size="2x" />
                </div>
                <div class="icon-text">
                    <font-awesome-icon class="arrow-icon float-right" icon="fa-solid fa-chevron-right" />
                    <div>Bankrekening toevoegen</div>
                    <p class="text-muted mb-0">Voeg je zakelijke bankrekening toe voor de uitbetalingen.</p>
                </div>
            </b-list-group-item>
            <b-list-group-item to="/manage/organisation/setup/documents">
                <div class="float-left">
                    <font-awesome-icon class="intro-icon mt-1" icon="fa-solid fa-file-invoice" size="2x" />
                </div>
                <div class="icon-text">
                    <font-awesome-icon class="arrow-icon float-right" icon="fa-solid fa-chevron-right" />
                    <div>Documenten uploaden</div>
                    <p class="text-muted mb-0">We hebben nog een aantal documenten van jullie organisatie nodig.</p>
                </div>
            </b-list-group-item>
            -->
        </b-list-group>
        <b-modal id="bankaccount" title="Bankrekening toevoegen" hide-header-close>
            <p>Je kunt eenvoudig je zakelijke rekeningnummer toevoegen door het doen van een eenmalige betaling. Wij ontvangen dan van je bank je IBAN en de tenaamstelling.</p>
            <p><strong>Let op, dit mag geen privé rekening zijn!</strong></p>
            <template #modal-footer="{ close }">
                <b-button variant="outline-secondary" @click="close()">Annuleren</b-button>
                <b-button variant="primary" to="/manage/organisation/setup/bank">Doorgaan</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                shop: {},
            }
        },
        computed: {
            isVisible: function() {
                if (this.shop.payment_provider_status == 0) {
                    return true;
                }
                return false;
            }
        },
        methods: {
            getOrganisation: function() {
                this.$axios.get("https://api.tixgo.nl/organisations/show")
                .then( response => {
                    this.shop = response.data;
                });
            },
        },
        created() {
        this.getOrganisation();
        }
    }
</script>

<style scoped>
    .intro-icon{
        background-color:#CA355B;
        font-size: 25px;
        padding:8px;
        color:#fff;
        border-radius:10px;
        height:25px;
        width:25px;
    }
    .icon-text{
        padding-left:60px;
    }
    .arrow-icon{
        float:right;
        top:50%;
        margin-top:15px;
        padding-left:15px;
        color:#999;
    }
</style>